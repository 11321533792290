import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            width="24px"
            height="24px"
        >
            <path
                d="M0,242V14A29,29,0,0,1,14,0H241a23.63,23.63,0,0,1,15,15V242c-3.23,6.56-7.91,11.58-15,14H14A29,29,0,0,1,0,242Zm218-24.22c0-23.95.05-47.57,0-71.19a120.21,120.21,0,0,0-1-14.93c-3.87-29.73-33-47.16-61.18-36.74a52.06,52.06,0,0,0-24,17.9V96.38H96.23V217.73H134v-5.65c0-19.49-.3-39,.2-58.47a38.52,38.52,0,0,1,4.22-16.09c4.47-8.68,15.42-13.14,24.72-11.32,8.85,1.73,14.28,7.88,16.17,18.6a49.53,49.53,0,0,1,.66,8.44c.06,15.82,0,31.65,0,47.48v17.06Zm-179.8-.12H75.61V96.26H38.2ZM35,57A22,22,0,1,0,56.86,35.09,21.78,21.78,0,0,0,35,57Z"
                fill="#0072b1"
            />
        </svg>
    </React.Fragment>,
    'LinkedIn'
);
