import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
    Container,
    CssBaseline,
    IconButton,
    Typography,
} from '@material-ui/core';
import LinkedIn from '../icons/LinkedIn';
import Helmet from 'react-helmet';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
    },
    grid: {
        height: '100%',
        width: '100%',
    }
}));

export default function Home() {
    const [loaded, setLoaded] = useState(false);
    const classes = useStyles();

    useEffect(() => setLoaded(true), []);

    return (
        !!loaded && (
            <Container component={'main'}>
                <CssBaseline />
                <Helmet>
                    <title>Emlyn Whittick</title>
                    <meta name="description" content="Emlyn Whittick" />
                    <meta charSet="utf-8" />
                    <html lang="en" />
                </Helmet>
                <Grid
                    container
                    className={classes.root}
                    justify={'center'}
                    alignContent={'center'}
                    alignItems={'center'}
                    spacing={2}
                >
                    <Grid item>
                        <Typography variant={'h3'}>Emlyn Whittick</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            size={'small'}
                            color={'secondary'}
                            disableElevation
                            href={'http://www.linkedin.com/in/emlynwhittick'}
                            target={'_blank'}
                            rel={'noopener'}
                            role={'button'}
                            aria-label={'Navigate to LinkedIn profile'}
                        >
                            <LinkedIn />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
        )
    );
}
